<script setup>
    import useToast from '@/Composables/useToast.js'
    import ToastNotification from '@/Components/Toast/ToastNotification.vue'

    const { notifications, removeNotification } = useToast()

</script>

<template>
    <div class="fixed top-2 right-1 sm:right-[9%] lg:right-[20%] 2xl:right-[30%] z-50 space-y-1.5 sm:space-y-2 text-right">
        <TransitionGroup
            appear
            enter-from-class="opacity-0"
            enter-active-class="duration-500"
            leave-active-class="duration-500"
            leave-to-class="opacity-0">
            <ToastNotification 
                @click.prevent="removeNotification(notification)" 
                v-for="notification in notifications" 
                :notification="notification" 
                :key="notification.id" />
        </TransitionGroup>
    </div>
</template>