<script setup>

	import PicksIcon from '@/Icons/PicksIcon.vue';
	import FantasyIcon from '@/Icons/FantasyIcon.vue';
	import LeaderboardIcon from '@/Icons/LeaderboardIcon.vue';
	import MyFighterIcon from '@/Icons/MyFighterIcon.vue';
	import MoreIcon from '@/Icons/MoreIcon.vue';
	import SettingsIcon from '@/Icons/SettingsIcon.vue';
	import InviteIcon from '@/Icons/InviteIcon.vue';
	import MessageIcon from '@/Icons/MessageIcon.vue';
	import LeaveLeagueIcon from '@/Icons/LeaveLeagueIcon.vue';
	import MediaIcon from '@/Icons/MediaIcon.vue';
	import { ref, computed } from 'vue';
	import Link from '@/Components/Link.vue';
	import { useRouter } from 'vue-router'

	const props = defineProps({
		league: Object,
		expandBottomNav: Boolean
	});

	const emit = defineEmits(['updateNavigation']);
	const router = useRouter()
	const leaderboardRoute = ref({ name: 'leaderboard.index', params: { leagueId: props.league.id } });

	window.axios.get(route('leaderboard.index', [props.league.id]))
		.then(({ data }) => {
			leaderboardRoute.value = data;
		})
		.catch()

	let updateNavigation = function(event) {
		if(!props.expandBottomNav){
			event.stopPropagation();
			emit('updateNavigation', true);
		} else {
			emit('updateNavigation', false);
		}
	}

	let leave = function(){
		
		if(props.league.isOwner && props.league.users_count > 1){
			router.push({ name: 'leagues.leave.edit', params: { leagueId: props.league.id } })
		} else if(confirm('Leaving this league removes all access and you must be re-invited to participate in the future. Are you sure you want to continue?')){
			window.axios.delete(route('leagues.leave.delete', props.league.id))
				.then((res) => {
					emit('updateNavigation', false);
					router.push({ name: 'leagues.index' })
				})
				.catch((err) => {
					console.log(err)
				});
		}

	}

	const inactiveStyle = computed(() => ({
		'transform': 'translateY(calc(100% - 74px))',
	}));

	const activeStyle = computed(() => ({
		'transform': 'translateY(0)',
	}));

</script>

<template>

	<div @click.stop="">

		<div 
			:style="[props.expandBottomNav ? activeStyle : inactiveStyle ]"	
			class="transition-all fixed bottom-0 left-0 w-full z-40 bg-surface-1 text-xs font-bold text-center"
			>
			<div class="w-full sm:w-5/6 lg:w-3/5 2xl:w-2/5 sm:m-auto bg-surface-2 border-t sm:border-x border-edge-1">
				<div class="flex">
					<Link 
						:to="{ name: 'leagues.upcoming.show', params: { leagueId: props.league.id } }"
						class="relative group text-content-1 fill-content-1 border-edge-1 pt-5 pb-4 px-2 sm:w-1/5 grow"
						>
						<span :class="[['leagues.upcoming.show', 'leagues.previous.show', 'picks.index'].includes($route.name) ? 'block' : 'hidden' ]" class="absolute group-hover:block -top-[1px] left-0 w-full h-1 bg-accent-1"></span>
						<PicksIcon class="inline-block w-5 pb-0.5" /><br />
						<span>Pick'ems</span>
					</Link>
					<Link 
						:to="{ name: 'fantasy.seasons.index', params: { leagueId: props.league.id } }"
						class="relative group text-content-1 fill-content-1 border-edge-1 pt-5 pb-4 px-2 sm:w-1/5 grow"
						>
						<span :class="[ $route.name.includes('fantasy') ? 'block' : 'hidden' ]" class="absolute group-hover:block -top-[1px] left-0 w-full h-1 bg-accent-1"></span>
						<FantasyIcon class="inline-block w-5 pb-0.5" /><br />
						<span>Fantasy</span>
					</Link>
					<Link 
						:to="leaderboardRoute" 
						class="relative group text-content-1 fill-content-1 border-edge-1 pt-5 pb-4 px-2 sm:w-1/5 grow">
						<span :class="[$route.name.includes('leaderboard.') ? 'block' : 'hidden' ]" class="absolute group-hover:block -top-[1px] left-0 w-full h-1 bg-accent-1"></span>
						<LeaderboardIcon class="inline-block w-5 pb-0.5" /><br />
						<span>Leaderboard</span>
					</Link>
					<Link
						:to="{ name: 'fighter.edit', params: { leagueId: props.league.id } }" 
						class="relative group text-content-1 fill-content-1 border-edge-1 pt-5 pb-4 px-2 sm:w-1/5 grow">
						<span :class="[($route.path.includes('/fighter')) ? 'block' : 'hidden' ]" class="absolute group-hover:block -top-[1px] left-0 w-full h-1 bg-accent-1"></span>
						<MyFighterIcon class="inline-block w-5 pb-0.5" /><br />
						<span>My Fighter</span>
					</Link>
					<a 
						@click.prevent="updateNavigation($event)" 
						href="#" 
						class="relative group text-content-1 fill-content-1 border-edge-1 pt-5 pb-4 px-4 sm:w-1/5 grow">
						<span class="absolute sm:group-hover:block hidden -top-[1px] left-0 w-full h-1 bg-accent-1"></span>
						<MoreIcon class="inline-block w-5 pb-0.5" /><br />
						<span>More</span>
					</a>
				</div>
				<div class="flex">
					<Link 
						v-if="league.isOwner || props.league.user_invite"
						:to="{ name: 'invites.create', params: { leagueId: props.league.id } }"
						class="relative group text-content-1 fill-content-1 border-edge-1 pt-5 pb-4 px-2 sm:w-1/5 grow"
						>
						<span :class="[['invites.create'].includes($route.name) ? 'block' : 'hidden' ]" class="absolute group-hover:block -top-[1px] left-0 w-full h-1 bg-accent-1"></span>
						<InviteIcon class="inline-block w-5 pb-0.5" /><br />
						<span>Invite<br />Users</span>
					</Link>
					<Link 
						v-if="league.users_count"
						:to="{ name: 'send-gif.edit', params: { leagueId: props.league.id } }"
						class="relative group text-content-1 fill-content-1 border-edge-1 pt-5 pb-4 px-2 sm:w-1/5 grow"
						>
						<span :class="[['send-gif.edit'].includes($route.name) ? 'block' : 'hidden' ]" class="absolute group-hover:block -top-[1px] left-0 w-full h-1 bg-accent-1"></span>
						<MessageIcon class="inline-block w-5 pb-0.5" /><br />
						<span>Send<br />GIF</span>
					</Link>
					<Link 
						:to="{ name: 'blogs.index' }"
						class="relative group text-content-1 fill-content-1 border-edge-1 pt-5 pb-4 px-2 sm:w-1/5 grow"
						>
						<span :class="[['blogs.index', 'blogs.show'].includes($route.name) ? 'block' : 'hidden' ]" class="absolute group-hover:block -top-[1px] left-0 w-full h-1 bg-accent-1"></span>
						<MediaIcon class="inline-block w-5 pb-0.5" /><br />
						<span>Matchup<br />Analyses</span>
					</Link>
					<Link 
						v-if="league.isOwner"
						:to="{ name: 'leagues.edit', params: { leagueId: props.league.id } }"
						class="relative group text-content-1 fill-content-1 border-edge-1 pt-5 pb-4 px-2 sm:w-1/5 grow"
						>
						<span :class="[['leagues.edit', 'league.users.index'].includes($route.name) ? 'block' : 'hidden' ]" class="absolute group-hover:block -top-[1px] left-0 w-full h-1 bg-accent-1"></span>
						<SettingsIcon class="inline-block w-5 pb-0.5" /><br />
						<span>League<br />Settings</span>
					</Link>
					<a 
						href="#"
						@click.prevent="leave"
						class="relative group text-content-1 fill-content-1 border-edge-1 pt-5 pb-4 px-2 sm:w-1/5 grow"
						>
						<span :class="[['league.leave.edit'].includes($route.name) ? 'block' : 'hidden' ]" class="absolute group-hover:block -top-[1px] left-0 w-full h-1 bg-accent-1"></span>
						<LeaveLeagueIcon class="inline-block w-5 pb-0.5" /><br />
						<span>Leave<br />League</span>
					</a>
				</div>
				<div class="flex justify-between border-edge-1 border-t py-2 px-3 sm:p-3 text-content-1 items-center">
					<a 
						href="https://www.buymeacoffee.com/mmapickems"
						target="_blank"
						class="block w-1/4"
						>
						<img src="https://cdn.buymeacoffee.com/buttons/v2/default-yellow.png" alt="Buy Me A Coffee" />
					</a>
					<div class="sm:text-lg">
						<a href="https://www.facebook.com/people/MMA-Pickems/100092543053451/" target="_blank"><font-awesome-icon icon="fa-brands fa-facebook" class="pr-2" /></a>
						<a href="https://www.instagram.com/playmmapickems" target="_blank"><font-awesome-icon icon="fa-brands fa-instagram" class="pr-2" /></a>
						<a href="https://x.com/playmmapickems" target="_blank"><font-awesome-icon icon="fa-brands fa-twitter" /></a>
					</div>
				</div>
			</div>
		</div>
			
	</div>

</template>