import { reactive, computed } from 'vue'

const state = reactive({
    widthMode: null,
    desktopWidth: 'sm:m-auto sm:w-5/6 lg:w-3/5 2xl:w-2/5',
    theme: 'default',
})

export default function(){
    const widthMode = computed(() => state.widthMode)
    const desktopWidth = computed(() => state.desktopWidth)
    const theme = computed(() => state.theme )

    const setWidthMode = (widthMode) => state.widthMode = widthMode
    const setTheme = (theme) => state.theme = theme

    return {
        widthMode,
        setWidthMode,
        desktopWidth,
        setTheme,
        theme,
    }
}