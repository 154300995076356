import { reactive } from 'vue'

export default reactive({
    dropdownExpanded: false,
    activeProfile: null,
    setProfile(participant){
        this.activeProfile = participant
        document.body.style.overflow = 'hidden'
    },
    closeProfile(){
        this.activeProfile = null
        document.body.style.overflow = 'auto'
    }
})