<script setup>
    import { useRouter } from 'vue-router'
    import notificationsStore from '@/Stores/notificationsStore'

    const props = defineProps({
        notification: Object,
        index: Number,
    })

    const emit = defineEmits(['close']);
    const router = useRouter()

    const redirect = async function() {
        await notificationsStore.close(props.index);
        if(notificationsStore.items.length === 0){
            notificationsStore.show_notifications = false;
        }
        router.push({ name: 'feedback.create' })
    }

</script>

<template>
    <div class="p-2">
        <div class="flex items-center justify-between">
            <h2 class="text-3xl mb-2">{{ notification.data.title }}</h2>
            <span class="text-sm">scroll &#9660;</span>
        </div>
        <div class="max-h-[350px] sm:max-h-[500px] overflow-y-auto" v-html="notification.data.body" />
        <div class="flex items-center justify-center mt-3">
            <a href="#" @click.prevent="redirect" class="font-semibold py-1 px-2 bg-accent-1 text-content-2 inline-block">
                Send feedback
            </a>
            <a href="#" class="py-1 px-2 inline-block text-content-1" @click.prevent="emit('close')">
                Close
            </a>
        </div>
    </div>
</template>