import { reactive, computed } from 'vue'

const state = reactive({
    notifications: [],
})

export default function(){
    let interval
    const notifications = computed(() => state.notifications)

    const setNotification = function(newNotification){

        if(typeof(interval) === 'undefined') startInterval()

        newNotification = {
            ...newNotification,
            updatedAt: new Date().getTime()
        }

        //If a notification already exists with this ID then update it
        state.notifications.forEach((notification, index) => {
            if(notification.id === newNotification.id) {
                state.notifications[index] = newNotification
            }
        })

        if(state.notifications.filter(notification => notification.id === newNotification.id).length > 0) return false

        //Otherwise create a new one
        state.notifications.push(newNotification)
    }

    const removeNotification = function(notificationToRemove){
        state.notifications.forEach((notification, index) => {
            if(notification.id === notificationToRemove.id) state.notifications.splice(index, 1)
        })
        
        if(state.notifications.length === 0) interval = clearInterval(interval)
    }

    const startInterval = function(){
        interval = setInterval(() => {
            //Periodically remove notifications
            state.notifications.forEach((notification) => {
                if(new Date().getTime() - notification.updatedAt >= 3000){
                    removeNotification(notification)
                }
            })
        }, 1000)
    }

    return {
        notifications,
        setNotification,
        removeNotification
    }
}