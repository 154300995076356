import './bootstrap'
import '../css/app.css'

import { createApp } from 'vue'
import { ZiggyVue } from '../../vendor/tightenco/ziggy'
import router from './router'
import App from '@/App.vue'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCopy, faCircleQuestion, faEye, faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';
import { faPen, faPencil, faDownload, faXmark, faShare, faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faTrashCan, faPlay, faPause, faCircleCheck, faCircleXmark, faChevronRight, faChevronDown, faChevronUp, faLock } from '@fortawesome/free-solid-svg-icons';
import { usePwa } from '@/Composables/usePwa'
import VueGtag from "vue-gtag"
import * as Sentry from "@sentry/vue"

const { createPwa } = usePwa()
createPwa()

library.add(faStarSolid, faStarRegular, faEye, faShare, faXmark, faDownload, faCircleQuestion, faPen, faPencil, faCircleCheck, faCircleXmark, faChevronRight, faChevronDown, faChevronUp, faCopy, faTrashCan, faPlay, faPause, faLock, faFacebook, faInstagram, faTwitter);

window.addEventListener('vite:preloadError', (event) => {
    window.location.reload()
})

const app = createApp(App)

if(import.meta.env.PROD){
    Sentry.init({
        app,
        dsn: "https://2b89ab57182fe867eeea8a06fd15c86c@o4508394045636608.ingest.us.sentry.io/4508394067853312",
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration(),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for tracing.
        // We recommend adjusting this value in production
        // Learn more at
        // https://docs.sentry.io/platforms/javascript/configuration/options/#traces-sample-rate
        tracesSampleRate: 1.0,
        
        // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
        tracePropagationTargets: [/^https:\/\/mmapickems\.com\/api/],
        
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        // Learn more at
        // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    });
}

app.use(ZiggyVue)
    .use(router)
    .use(VueGtag, {
        bootstrap: false,
        config: { id: 'G-QV1ZW6NF5Q' },
        router
    })
    .component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app');